<template>
  <CardToolbar
    :show-toolbar-buttons="false"
    :description="description"
    :title="title"
  >
    <template v-slot:body>
      <apexchart
        height="200%"
        ref="playlistSubscriberIncreaseChart"
        type="area"
        :options="options"
        :series="series"
      ></apexchart>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { platforms } from "@/assets/static/platforms";
import moment from "moment";
export default defineComponent({
  name: "PlaylistSubscribersIncreaseChart",
  data() {
    return {
      days: 365,
    };
  },
  components: { CardToolbar },
  props: {
    description: {
      type: String,
    },
    platform: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
    chartData: {
      default: null,
    },
    color: {
      type: String,
    },
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    // const baseColor = platforms[props.platform].color;
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.8,
          opacityTo: 0.3,
        },
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [props.color],
      },
      xaxis: {
        categories: props.chartData.xAxis ? props.chartData.xAxis : "",
        tickPlacement: "between",
        tickAmount: 30,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          formatter: (value) => {
            return moment(value, "MM-DD-YYYY").format("MMM YYYY");
          },
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: props.color,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (value) {
            return Number(value).toLocaleString();
          },
        },
        x: {
          formatter: (value) => {
            return moment(value, "MM-DD-YYYY").format("MMM DD, YYYY");
          },
        },
      },
      colors: [props.color],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: props.color,
        strokeWidth: 3,
      },
    });
    const series = ref([
      {
        name: props.tooltipText ? props.tooltipText : "",
        data: props.chartData.yAxis ? props.chartData.yAxis : [],
      },
    ]);
    return {
      options,
      series,
    };
  },
});
</script>
