<template>
  <CardToolbar
    :show-toolbar-buttons="true"
    description="Playlist of an Artist"
    title="Playlist"
  >
    <template v-slot:toolbar>
      <div class="d-flex justify-content-end">
        <el-select
          size="medium"
          v-model="selectedPlatform"
          placeholder="Select Platform"
          @change="handlePlatformChange"
        >
          <el-option key="spotify" label="Spotify" value="spotify"> </el-option>
          <el-option key="deezer" label="Deezer" value="deezer"> </el-option>
          <el-option key="youtube" label="Youtube" value="youtube"> </el-option>
        </el-select>
      </div>
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortTableData"
        v-loading="loading"
        :fit="true"
        size="medium"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="song.name" label="SONG" />
        <el-table-column prop="playlist.name" label="PLAYLIST" />
        <el-table-column
          sortable="custom"
          prop="playlist.latestSubscriberCount"
          label="LIKES"
        />
        <el-table-column sortable="custom" prop="position" label="POSITION" />
        <el-table-column sortable="custom" prop="entryDate" label="ADDED ON" />
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="paginationObject.offset"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="paginationObject.limit"
        layout="sizes, prev, pager, next"
        :total="paginationObject.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </template>
  </CardToolbar>
  <CardToolbar v-if="loadingCharts" :show-toolbar-buttons="false">
    <template v-slot:body>
      <div v-loading="loadingCharts" class="mb-20"></div>
    </template>
  </CardToolbar>
  <PlaylistSubscribersIncreaseChart
    v-if="playlistChartData.playlistIncrease"
    :description="'Running Total Of Playlists Increases'"
    :title="'Total Playlists'"
    :tooltipText="'Total Playlists'"
    :platform="'spotify'"
    :chartData="playlistChartData.playlistIncrease"
    :color="'#00A1F9'"
  />
  <PlaylistSubscribersChart
    v-if="playlistChartData.playlistChart"
    :description="'Running Total Of Playlists'"
    :title="'New Playlists'"
    :tooltipText="'New Playlists'"
    :platform="'spotify'"
    :chartData="playlistChartData.playlistChart"
    :color="'#00A1F9'"
  />
  <SocialAnalytics :uuid="$route.params.uuid" platform="spotify" />
  <PlaylistSubscribersIncreaseChart
    v-if="playlistChartData.subscriberIncrease"
    :description="'Running Total Of Playlist Subscribers Increases'"
    :title="'Total Playlist Subscribers'"
    :tooltipText="'Total Playlist Subscribers'"
    :platform="'spotify'"
    :chartData="playlistChartData.subscriberIncrease"
    :color="'#800080'"
  />
  <PlaylistSubscribersChart
    v-if="playlistChartData.subcriberChart"
    :description="'Running Total Of Playlist Subscribers'"
    :title="'New Subscribers'"
    :tooltipText="'New Subscribers'"
    :platform="'spotify'"
    :chartData="playlistChartData.subcriberChart"
    :color="'#800080'"
  />
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import CardToolbar from "../../common/components/CardToolbar";
import {
  getPaginatedArtistPlaylist,
  getPlaylistOfArtists,
} from "@/api/artists.api";
import SocialAnalytics from "../components/SocialAnalytics";
import PlaylistSubscribersChart from "./playlistSubscriberChart";
import PlaylistSubscribersIncreaseChart from "./PlaylistSubscribersIncreaseChart";

export default {
  name: "Playlist",
  components: {
    CardToolbar,
    PlaylistSubscribersIncreaseChart,
    PlaylistSubscribersChart,
    SocialAnalytics,
  },
  setup() {
    const tableData = ref([]);
    const selectedPlatform = ref("spotify");
    const loading = ref(false);
    const loadingCharts = ref(false);
    const playlistChartData = ref([]);
    const route = useRoute();
    const paginationObject = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "entryDate",
      sortOrder: "desc",
    });
    onMounted(async () => {
      try {
        loading.value = true;
        loadingCharts.value = true;
        await fetchPlaylistData(
          route.params.uuid,
          selectedPlatform.value,
          paginationObject.value
        ).then(() => (loading.value = false));
        await fetchPlaylistOfArtists(route.params.uuid, "spotify").then(
          () => (loadingCharts.value = false)
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
        loadingCharts.value = false;
      }
    });

    const fetchPlaylistData = async (uuid, platform, paginationObj) => {
      const { data } = await getPaginatedArtistPlaylist(
        uuid,
        platform,
        paginationObj.offset - 1,
        paginationObj.limit,
        paginationObj.sortBy,
        paginationObj.sortOrder
      );
      tableData.value = data.items;
      paginationObject.value = {
        ...paginationObject.value,
        limit: data.page.limit,
        offset: data.page.offset + 1,
        total: data.page.total,
      };
    };

    const fetchPlaylistOfArtists = async (uuid, platform) => {
      const {
        data: { data },
      } = await getPlaylistOfArtists(uuid, platform);
      playlistChartData.value = data;
    };

    const handleSizeChange = async (val) => {
      try {
        loading.value = true;
        paginationObject.value.limit = val;
        await fetchPlaylistData(
          route.params.uuid,
          selectedPlatform.value,
          paginationObject.value
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };
    const handleCurrentChange = async (val) => {
      try {
        loading.value = true;
        paginationObject.value.offset = val;
        await fetchPlaylistData(
          route.params.uuid,
          selectedPlatform.value,
          paginationObject.value
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const sortTableData = async ({ column, prop, order }) => {
      paginationObject.value.sortBy = prop ? prop : "entryDate";
      if (prop) {
        paginationObject.value.sortBy =
          prop === "position" ? "position" : "subscriberCount";
      } else {
        paginationObject.value.sortBy = "entryDate";
      }
      if (order === "ascending") {
        paginationObject.value.sortOrder = "asc";
      } else {
        paginationObject.value.sortOrder = "desc";
      }
      try {
        loading.value = true;
        await fetchPlaylistData(
          route.params.uuid,
          selectedPlatform.value,
          paginationObject.value
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const handlePlatformChange = async (platform) => {
      try {
        loading.value = true;
        await fetchPlaylistData(
          route.params.uuid,
          platform,
          paginationObject.value
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    return {
      tableData,
      loading,
      selectedPlatform,
      paginationObject,
      loadingCharts,
      handleSizeChange,
      handleCurrentChange,
      handlePlatformChange,
      sortTableData,
      playlistChartData,
    };
  },
};
</script>

<style scoped></style>
