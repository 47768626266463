<template>
  <CardToolbar
    :show-toolbar-buttons="false"
    :description="description"
    :title="title"
  >
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="10" animated />
      <el-row v-else :gutter="16">
        <el-col :span="24">
          <div class="mb-5 mb-xl-10 border p-2">
            <apexchart
              ref="playlistSubscriberChart"
              type="bar"
              height="350"
              :series="series"
              :options="options"
            ></apexchart>
          </div>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { defineComponent, ref } from "vue";
import moment from "moment";
import { getCSSVariableValue } from "@/assets/ts/_utils";
export default defineComponent({
  name: "PlaylistSubscriberChart",
  data() {
    return {
      days: 365,
    };
  },
  components: { CardToolbar },
  props: {
    description: {
      type: String,
    },
    platform: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
    chartData: {
      default: null,
    },
    color: {
      type: String,
    },
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const options = ref({
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15%",
          colors: {
            ranges: [
              {
                from: -100000000000,
                to: 0,
                color: "#F15B46",
              },
              {
                from: 0,
                to: 100000000000,
                color: props.color,
              },
            ],
          },
        },
      },
      stroke: {
        show: true,
        width: 10,
        colors: ["transparent"],
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 0.8,
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: props.chartData.xAxis ? props.chartData.xAxis : "",
        tickPlacement: "between",
        tickAmount: 30,
        labels: {
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          formatter: (value) => {
            return moment(value, "MM-DD-YYYY").format("MMM YYYY");
          },
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (value) {
            return Number(value).toLocaleString();
          },
        },
        x: {
          formatter: (value) => {
            return moment(value, "MM-DD-YYYY").format("MMM DD, YYYY");
          },
        },
      },
    });
    const series = ref([
      {
        name: props.tooltipText ? props.tooltipText : "",
        data: props.chartData.yAxis ? props.chartData.yAxis : [],
      },
    ]);
    return {
      options,
      series,
    };
  },
});
</script>
